import { useLocation } from 'react-router';
import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import { useStartPageQuery } from '../StartPage/useStartPageQuery';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { startPageComponents, StartPageWrapper } from '../StartPage/StartPage';
import StartPageProductGrid from '../StartPage/Content/StartPageProductGrid';
import { DynamicContentRenderer } from '../ContentEditor/DynamicContentRenderer';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

// /kundeservice, /gavekort /black-friday
const contentCategoryPages = [165, 1620, 1899];

const CategoryPage = props => {
  const location = useLocation();
  const { startPage } = useStartPageQuery();
  const isContentCategory = contentCategoryPages.includes(props?.category?.id);

  return (
    <>
      <StartPageWrapper>
        <DynamicContentRenderer categoryId={props?.category?.id} />
        {/* Check startpage && instead ??  */}
        {location.pathname.split('/')?.length < 3 && !isContentCategory && (
          <>
            <StartPageRenderer
              result={{ data: { startPage: startPage } }}
              LoadingPage={LoadingPage}
              startPageComponents={startPageComponents}
              ProductGrid={StartPageProductGrid}
            />
          </>
        )}
      </StartPageWrapper>
      {!isContentCategory && <LoadableStandardCategoryPage {...props} />}
    </>
  );
};

export default CategoryPage;
