import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import MenuImageQuery from './MenuImageQuery.gql';
import { Query } from 'react-apollo';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import AnimateHeight from 'react-animate-height';
import { ReactComponent as AngleRight } from '../../../../svg/AngleRight.svg';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 30px 20px;
  max-width: 1288px;
  max-width: 100%;
  flex: 1;

  columns: 4;
  display: block;

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    display: block;
    :hover {
      text-decoration: underline;
    }
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: fit-content;
  margin: 0 auto;
  min-width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;

  > div > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1288px;
    padding: 0px 20px;
    margin: auto;
  }
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
  border-top: 1px solid #dedede;
  &.open {
    border-bottom: 1px solid #dedede;
  }
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;

const NoThirdTierItems = styled('div')`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  width: 100%;
  display: inline-block;
  text-align: left;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding-left: 15px;
  border-left: 1px solid #ddd;

  h2 {
    font-size: inherit;
    svg {
      width: 8px;
      margin-left: 10px;
      margin-top: -2px;
      opacity: 0.6;
    }
  }
`;

const MenuImage = styled('div')`
  flex: 1;
  padding: 20px 20px 20px 40px;
  max-width: 320px;
  background: #f5f5f5;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 50vw;
    background: #f5f5f5;
  }
  div {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  a {
    display: block;
    width: 100%;
  }
`;

const MenuImageContent = styled('div')`
  text-align: center;
  width: 100%;

  a {
    text-decoration: none;
  }
  h3 {
    color: #333333;
    font-size: 1rem;
    line-height: 1;
    margin: 10px auto 5px;
    padding: 0;

    font-size: 10px;
    text-transform: uppercase;
  }
  h2 {
    color: #333333;
    font-size: 1.2rem;
    line-height: 1;
    margin: 0 0 10px 0;
    padding: 0;

    font-size: 12px;
    text-transform: uppercase;
  }
  span {
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    background: ${theme.colors.blue};
    color: white;
    font-weight: bold;
    font-size: 11px;
  }
`;

const Menybild = ({ image, link, children }) => (
  <MenuImageContent>
    <Link to={link}>
      <Image src={image} cover aspect={'680:450'} alt={image} />
      {children}
    </Link>
  </MenuImageContent>
);

const MenuImageItem = ({ closeNav, id }) => {
  return (
    <Query query={MenuImageQuery} variables={{ id: id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error!: ${error}`;
        return data.category ? (
          <div onClick={closeNav}>
            <Shortcodes
              content={data.category.content}
              components={{
                Menybild: Menybild
              }}
            />
          </div>
        ) : null;
      }}
    </Query>
  );
};

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <Wrapper>
      <InnerWrapper className={pose === 'open' ? 'open' : 'closed'}>
        <ContentFit>
          <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
            <MaxWidth>
              {activeCategory?.subcategories.length > 0 &&
                activeCategory.subcategories.map((cat, index) => (
                  <React.Fragment key={cat.id}>
                    {cat?.subcategories?.length > 0 ? (
                      <SubMenuSection
                        heading={cat}
                        categories={cat.subcategories}
                        onSelect={closeNav}
                        key={cat.id}
                      />
                    ) : (
                      <React.Fragment key={index}>
                        {cat.name !== 'Menybild' ? (
                          <NoThirdTierItems key={index}>
                            <h2>
                              <CategoryLink category={cat} onClick={closeNav}>
                                {cat.images?.length > 0 && (
                                  <img
                                    src={cat.images[0].url}
                                    alt={cat.name}
                                    style={{
                                      marginRight: '8px',
                                      width: '25px',
                                      height: 'auto'
                                    }}
                                  />
                                )}
                                {cat.name}
                                <AngleRight />
                              </CategoryLink>
                            </h2>
                          </NoThirdTierItems>
                        ) : null}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
            </MaxWidth>
            {activeCategory &&
              activeCategory.subcategories.map((cat, index) =>
                cat.name === 'Menybild' ? (
                  <MenuImage key={index}>
                    <MenuImageItem closeNav={closeNav} id={cat.id} />
                  </MenuImage>
                ) : null
              )}
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
